<template>
  <div class="content-box" style="margin-top: 70px">
    <div class="black-box"></div>
    <!-- {{ acuInfo }} -->
    <div class="acu" :class="acuLine">
      <div class="img flex flex-center">
        <img src="@/assets/imgs/acu.png" style="height: 80px" />
      </div>
      <div class="acu-status">
        <div
          class="info-content"
          v-show="acuInfo.state === '运行' || acuInfo.state == 2"
        >
          <div class="point bg-green"></div>
          <div class="text-green">{{ $t("sysData.operation") }}</div>
        </div>
        <div
          class="info-content"
          v-show="acuInfo.state === '故障' || acuInfo.state == 3"
        >
          <div class="point bg-red"></div>
          <div class="text-red">{{ $t("sysData.breakDowm") }}</div>
        </div>
        <div
          class="info-content"
          v-show="acuInfo.state === '空闲' || acuInfo.state == 1"
        >
          <div class="point bg-purple"></div>
          <div class="text-purple">空闲</div>
        </div>
      </div>
      <div class="acu-data">
        <list-info-3 :value="acuInfo.power" unit="kW"></list-info-3>
        <!-- <list-info-3 :value="acuInfo.voltage" unit="V"></list-info-3> -->
      </div>
    </div>
  </div>
</template>

<script>
import listInfo3 from "./list-info3.vue";
export default {
  components: { listInfo3 },
  props: {
    acuInfo: {
      type: Object,
      default() {
        return {
          power: 0,
          voltage: 0,
          state: 1,
        };
      },
    },
    acuLine: {
      type: String,
      default: "",
    },
  },
  watch: {
    acuInfo(newValue) {
      this.acuInfo = newValue;
    },
    acuLine(newValue) {
      this.acuLine = newValue;
    },
  },
};
</script>

<style scoped>
.black-box {
  height: 70px;
  width: 80px;
}
.acu {
  position: relative;
}
.acu-status {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 56%;
  top: 50%;
}
.acu-data {
  position: absolute;
  width: 100px;
  left: 40%;
  top: 120%;
}
.acu:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 100%;
  height: 70px;
  top: -88%;
  left: 50%;
}
.acu:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 100%;
  height: 70px;
  top: 100%;
  left: 50%;
}

.acuTop {
  position: relative;
}
.acuTop:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, transparent, #01feff);
  background-size: 100% 50%;
  height: 70px;
  top: -88%;
  left: 50%;
  animation: dongTop 2s linear infinite;
}
.acuTop:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, transparent, #01feff);
  background-size: 100% 50%;
  height: 70px;
  top: 100%;
  left: 50%;
  animation: dongTop 2s linear infinite;
}

.acuBottom {
  position: relative;
}
.acuBottom:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  height: 70px;
  top: -88%;
  left: 50%;
  animation: dongBottom 2s linear infinite;
}
.acuBottom:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  height: 70px;
  top: 100%;
  left: 50%;
  animation: dongBottom 2s linear infinite;
}
.point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.info-content {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
@keyframes dongLeft {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes dongRight {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes dongBottom {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes dongTop {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0;
  }
}
</style>

import { render, staticRenderFns } from "./load3.vue?vue&type=template&id=394a0686&scoped=true&"
import script from "./load3.vue?vue&type=script&lang=js&"
export * from "./load3.vue?vue&type=script&lang=js&"
import style0 from "./load3.vue?vue&type=style&index=0&id=394a0686&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394a0686",
  null
  
)

export default component.exports
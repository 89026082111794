<template>
  <div class="box">
    <div class="content-box">
      <div class="title">
        <lable-name name="站点信息"></lable-name>
      </div>
      <div
        class="item-box flex flex-center items-middle"
        style="height: calc(100% - 50px)"
      >
        <div
          class="flex space-start"
          style="width: 90% !important"
          v-if="siteId != 'EnerArk1.1P_202301080027'"
        >
          <div class="left" style="width: 70% !important">
            <div class="line-box">
              <div class="line" :class="siteClass.sysClass"></div>
              <div class="sys-data">
                <list-info-3 :value="siteInfo.sysPower" unit="kW"></list-info-3>
              </div>
            </div>
            <template v-if="acuInfo.length > 0">
              <div class="acu-info flex space-around">
                <div class="acu" v-for="(v, i) in acuInfo" :key="i">
                  <acu :acuInfo="v" :acuLine="acuLine[i]"></acu>
                </div>
              </div>
              <div class="device-info flex space-around" style="width: 100%">
                <div
                  class="device"
                  v-for="(v, i) in deviceInfo1"
                  :key="i"
                  style="width: 100%"
                >
                  <device
                    :device-info="v"
                    :acuLine="acuLine[i]"
                    :siteName="siteName"
                    :site-name="siteName"
                  ></device>
                </div>
              </div>
            </template>
            <div
              class="device-info flex space-around"
              style="width: 100%"
              v-else
            >
              <div
                class="device"
                v-for="(v, i) in deviceInfo1"
                :key="i"
                style="width: 100%"
              >
                <device-2
                  :device-info="v"
                  :acuLine="acuLine[i]"
                  :siteName="siteName"
                ></device-2>
              </div>
            </div>
          </div>
          <div class="load-box">
            <div class="content">
              <load
                :acu-info="{
                  power: siteInfo.loadPower,
                  class: siteClass.loadClass,
                }"
              ></load>
            </div>
          </div>
          <div class="blank-box">
            <div class="grid" :class="siteClass.gridClass"></div>
          </div>
          <div class="grid-box">
            <div class="grid-img">
              <img src="@/assets/imgs/power.png" style="width: 80px" />
            </div>
            <div class="grid-data">
              <list-info-3 :value="siteInfo.gridPower" unit="kW"></list-info-3>
            </div>
          </div>
        </div>
        <div
          class="flex space-start"
          style="width: 90% !important; height: 100%"
          v-else
        >
          <ygzj
            :device-info="deviceInfo1"
            :acuInfo="acuInfo"
            :acuLine="acuLine"
            :site-info="siteInfo"
          ></ygzj>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { oneSiteHomeInfo } from "../../api/siteList";
import LableName from "../../components/LableName.vue";
import Acu from "./components/acu.vue";
import device from "./components/device.vue";
import Device2 from "./components/device2.vue";
import ListInfo3 from "./components/list-info3.vue";
import Load from "./components/load.vue";
import Ygzj from "./components/ygzj.vue";
export default {
  components: { device, Acu, Load, LableName, ListInfo3, Device2, Ygzj },
  data() {
    return {
      siteId: "",
      siteInfo: {
        gridPower: 10,
        sysPower: 20,
        loadPower: 30,
      },
      siteClass: {
        gridClass: "",
        sysClass: "",
        loadClass: "",
      },
      deviceInfo: [
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
        {
          state: 1,
          current: 0,
          voltage: 0,
          power: 0,
          soc: 0,
          deviceClass: "",
        },
      ],
      acuLine: [],
      acuInfo: [],
      deviceInfo1: [],
      timer: null,
    };
  },
  props: {
    isFresh: {
      type: Boolean,
      default() {
        return false;
      },
    },
    siteName: {
      type: String,
      default() {
        return "";
      },
    },
  },
  created() {
    this.siteId = this.$route.query.id;
    this.rateId = this.$route.query.rateId;
    this.getLine();
    this.getDevice();
  },
  mounted() {
    this.timer = setInterval(() => {
      this.changeDevice();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getDevice() {
      oneSiteHomeInfo({ siteId: this.siteId }).then((res) => {
        let data = res.data.data.arkInfo;
        data.forEach((v) => {
          v.power = parseFloat(v.power) * -1;
        });
        this.deviceInfo = JSON.parse(JSON.stringify(data));
        this.deviceInfo.sort((a, b) => {
          return a.arkName.slice(7) - b.arkName.slice(7);
        });
        this.acuInfo = [];
        this.getLine();
        // debugger;
        this.siteInfo.sysPower = parseFloat(res.data.data.siteInfo.sysPower);
        this.siteInfo.gridPower = parseFloat(res.data.data.siteInfo.gridPower);
        this.siteInfo.loadPower = parseFloat(res.data.data.siteInfo.loadPower);
        let number = 8;
        if (this.siteId.includes("EnerArk1.1P_202301080027")) {
          number = 6;
        }
        let n = parseInt(this.deviceInfo.length / number);
        let m = this.deviceInfo.length % number == 0 ? 0 : 1;
        for (let i = 0; i < m + n; i++) {
          let arr = this.deviceInfo.slice(i * number, i * number + number);
          this.deviceInfo1.push(arr);
          let obj = {
            state: 1,
            current: 0,
            voltage: 0,
            power: 0,
            soc: 0,
            deviceClass: "",
          };
          if (this.deviceInfo.length > number - 1) {
            obj.power = this.deviceInfo1[i].reduce(
              (accumulator, currentValue) =>
                parseFloat(
                  parseFloat(accumulator) + parseFloat(currentValue.power)
                ),
              0
            );
            obj.state = this.getAcuState(this.deviceInfo1[i]);
            this.acuInfo.push(obj);
          }
        }
        this.getLine();
      });
    },
    changeDevice() {
      oneSiteHomeInfo({ siteId: this.siteId }).then((res) => {
        let data = res.data.data.arkInfo;
        data.forEach((v) => {
          v.power = parseFloat(v.power) * -1;
        });
        this.deviceInfo = JSON.parse(JSON.stringify(data));
        this.deviceInfo.sort((a, b) => {
          return a.arkName.slice(7) - b.arkName.slice(7);
        });
        this.siteInfo.sysPower = parseFloat(res.data.data.siteInfo.sysPower);
        this.siteInfo.gridPower = parseFloat(res.data.data.siteInfo.gridPower);
        this.siteInfo.loadPower = parseFloat(res.data.data.siteInfo.loadPower);
        this.getLine();
        this.acuInfo = [];
        // this.siteInfo=res.data.data.siteInfo
        let number = 8;
        if (this.siteId.includes("EnerArk1.1P_202301080027")) {
          number = 6;
        }
        let n = parseInt(this.deviceInfo.length / number);
        let m = this.deviceInfo.length % number == 0 ? 0 : 1;
        for (let i = 0; i < m + n; i++) {
          let arr = this.deviceInfo.slice(i * number, i * number + number);
          this.deviceInfo1[i] = JSON.parse(JSON.stringify(arr));
          let obj = {
            state: 1,
            current: 0,
            voltage: 0,
            power: 0,
            soc: 0,
            deviceClass: "",
          };
          if (this.deviceInfo.length > 5) {
            obj.power = this.deviceInfo1[i].reduce(
              (accumulator, currentValue) =>
                parseFloat(
                  parseFloat(accumulator) + parseFloat(currentValue.power)
                ),
              0
            );
            obj.state = this.getAcuState(this.deviceInfo1[i]);
            this.acuInfo[i] = JSON.parse(JSON.stringify(obj));
          }
        }
        this.getLine();
        console.log(this.deviceInfo1);
      });
    },
    getAcuState(arr) {
      let state = 1;
      console.log(arr, "arr");
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].status == "运行") {
          state = 2;
          console.log(state);
          break;
        }
      }
      console.log(state);
      return state;
    },
    getLine() {
      //根据电流正负判断方向
      this.deviceInfo.forEach((v) => {
        if (v.power <= -2) {
          // v.deviceClass = "arkTop";
          v.deviceClass = "arkBottom";
        } else if (v.power >= 2) {
          v.deviceClass = "arkTop";
        } else {
          v.deviceClass = "ark";
        }
      });
      this.deviceInfo1.forEach((v, i) => {
        let power = v.reduce(
          (accumulator, currentValue) =>
            parseFloat(
              parseFloat(accumulator) + parseFloat(currentValue.power)
            ),
          0
        );

        if (power > 2) {
          this.acuLine[i] = "acuTop";
          // this.acuLine[i] = "acuBottom";
        } else if (power < -2) {
          // this.acuLine[i] = "acuTop";
          this.acuLine[i] = "acuBottom";
        } else {
          this.acuLine[i] = "acu";
        }
      });
      if (this.siteInfo.sysPower >= 2) {
        this.siteClass.sysClass = "lineRight";
      } else if (this.siteInfo.sysPower <= -2) {
        this.siteClass.sysClass = "lineLeft";
      } else {
        this.siteClass.sysClass = "";
      }
      if (this.siteInfo.gridPower >= 2) {
        // this.gridClass = "gridRight";
        this.siteClass.gridClass = "gridLeft";
      } else if (this.siteInfo.gridPower <= -2) {
        this.siteClass.gridClass = "gridRight";
        // this.gridClass = "gridLeft";
      } else {
        this.siteClass.gridClass = "";
      }
      if (this.siteInfo.loadPower >= 2) {
        this.siteClass.loadClass = "loadBottom";
      } else {
        this.siteClass.loadClass = "";
      }
    },
  },
};
</script>

<style scoped>
.box {
  height: 80vh;
  overflow: auto;
  background: #222e4a;
  box-sizing: border-box;
}
.content-box {
  background: #222e4a;
  height: 100%;
}
.device,
.acu {
  margin-left: 50px;
}
.line-box {
  position: relative;
}
.sys-data {
  width: 100px;
  position: absolute;
  left: 65%;
  top: 30px;
}
.line {
  position: absolute;
  width: 95%;
  height: 3px;
  background-image: linear-gradient(to right, #01feff, transparent);
  background-size: 100% 100%;
  left: 14%;
  top: 83px;
}
.lineRight {
  position: absolute;
  width: 95%;
  height: 3px;
  left: 14%;
  top: 83px;
  background-image: linear-gradient(to left, #01feff, transparent);
  background-size: 50% 100%;
  animation: dongRight 2s linear infinite;
}
.lineLeft {
  position: absolute;
  width: 95%;
  height: 3px;
  left: 14%;
  top: 83px;
  background-image: linear-gradient(to right, #01feff, transparent);
  background-size: 50% 100%;
  animation: dongLeft 2s linear infinite;
}

.grid-box {
  position: relative;
}
.grid-data {
  position: absolute;
  width: 100%;
  left: -150%;
  top: 5%;
}
.grid-img {
  margin-top: 50%;
}
.blank-box {
  width: 120px;
}
.grid {
  position: relative;
  width: 170%;
  height: 3px;
}
.grid:before {
  content: "";
  position: absolute;
  background-image: linear-gradient(to right, #01feff, transparent);
  background-size: 100% 100%;
  width: 100%;
  height: 3px;
  top: 83px;
  left: -36%;
}
.gridRight {
  position: relative;
}

.gridRight:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  top: 83px;
  left: -36%;
  background-image: linear-gradient(to left, #01feff, transparent);
  background-size: 50% 100%;
  animation: dongRight 2s linear infinite;
}

.gridLeft {
  position: relative;
}

.gridLeft:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  top: 83px;
  left: -36%;
  background-image: linear-gradient(to right, #01feff, transparent);
  background-size: 50% 100%;
  animation: dongLeft 2s linear infinite;
}
@keyframes dongLeft {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes dongRight {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes dongBottom {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes dongTop {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0;
  }
}
</style>

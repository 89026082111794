<template>
  <div class="list-info flex">
    <div class="value">{{ value | num }}</div>
    <div class="unit text-labelColor">{{ unit }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "" | 0,
    },
    unit: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="stylus" scoped>
.list-info{
  text-align: center
  margin:15px  0px
  width 100%
  margin-left: -50%
}
.value{
  flex: 2
  font-size:12px
  color: #fff
  font-weight:800
}
.unit{
  flex: 1
  font-size:12px
}
</style>

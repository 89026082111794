<template>
  <div class="flex flex-col flex-center items-middle">
    <div class="line"></div>
    <div class="byq-box flex-col flex-center items-middle">
      <div class="blank-box1"></div>
      <div class="byq">
        <img src="@/assets/imgs/byq.png" style="height: 50px" />
      </div>
    </div>
    <div class="content-box">
      <div class="black-box"></div>
      <div class="loadBottom">
        <div class="img flex flex-center">
          <img src="@/assets/imgs/yh.png" style="height: 80px" />
        </div>
        <!-- <div class="load-data">
          <list-info-3 :value="acuInfo.power" unit="kW"></list-info-3>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import listInfo3 from "./list-info3.vue";
export default {
  // components: { listInfo3 },
  props: {
    acuInfo: {
      type: Object,
      default() {
        return {
          power: 0,
          class: "",
        };
      },
    },
  },
  watch: {
    acuInfo(newValue) {
      this.acuInfo = newValue;
    },
  },
};
</script>

<style scoped>
.content-box {
  margin-top: -90px;
}
.black-box1 {
  height: 180px;
  width: 200px;
}
.black-box {
  height: 162px;
  width: 200px;
}
.load {
  position: relative;
}
.load-data {
  position: absolute;
  width: 100px;
  left: 20%;
  top: -80%;
}
.load:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  //background-image: linear-gradient(to top, #01feff, transparent);
  background: #07d3db;
  background-size: 100% 100%;
  height: 79px;
  top: -93%;
  left: 50%;
}
.load:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  //background-image: linear-gradient(to top, #01feff, transparent);
  background: #07d3db;
  background-size: 100% 100%;
  height: 63px;
  top: -230%;
  left: 50%;
}

.loadTop {
  position: relative;
}
.loadTop:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, transparent, #01feff);
  background-size: 100% 50%;
  height: 80px;
  top: -95%;
  left: 50%;
  animation: dongTop 2s linear infinite;
}

.loadBottom {
  position: relative;
}
.loadBottom:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  height: 65px;
  top: -234%;
  left: 50%;
  animation: dongBottom 2s linear infinite;
}
.loadBottom:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  height: 80px;
  top: -95%;
  left: 50%;
  animation: dongBottom 2s linear infinite;
}
@keyframes dongLeft {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes dongRight {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes dongBottom {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes dongTop {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0;
  }
}
</style>

<template>
  <div class="ygzj-box flex flex-center">
    <div class="left flex">
      <div v-for="(v, i) in deviceInfo" :key="i">
        <div>
          <byq-1
            :device-info="v"
            :acuInfo="acuInfo[i]"
            :acuLine="acuLine[i]"
            :is-show-line="i > 0 ? false : true"
          ></byq-1>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="line"></div>
      <div class="blank-box" style="height: 60px"></div>
      <load-2></load-2>
    </div>
    <div class="right flex" style="margin-top: -83px">
      <!-- <div class="blank-box">
        <div class="grid"></div>
      </div> -->
      <div class="grid-box">
        <div class="grid-img">
          <img src="@/assets/imgs/power.png" style="width: 65px" />
        </div>
        <div class="grid-data">
          <list-info-3 unit="kW" :value="siteInfo.gridPower"></list-info-3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Byq1 from "./byq1.vue";
import ListInfo3 from "./list-info3.vue";
import Load2 from "./load2.vue";
export default {
  components: { ListInfo3, Byq1, Load2 },
  data() {
    return {
      isShowLine: false,
    };
  },
  props: {
    deviceInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    acuInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    acuLine: {
      type: Array,
      default() {
        return [];
      },
    },
    siteInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    deviceInfo(newValue) {
      this.deviceInfo = newValue;
    },
    acuInfo(newValue) {
      this.acuInfo = newValue;
    },
    acuLine(newValue) {
      this.acuLine = newValue;
    },
    siteInfo(newValue) {
      this.siteInfo = newValue;
    },
  },
};
</script>

<style scoped>
.ygzj-box {
  width: 100%;
  margin-top: 50px;
  background: #222e4a;
}
.blank-box {
  height: 120px;
}
.line {
  margin-left: -488%;
  width: 583%;
  height: 3px;
  background-image: linear-gradient(to right, #01feff, transparent);
  background-size: 50% 100%;
  animation: dongLeft 2s linear infinite;
}
.grid-box {
  position: relative;
}
.grid-data {
  position: absolute;
  width: 100%;
  left: -70%;
  top: 5%;
}
.grid-img {
  margin-top: 70%;
}
.blank-box {
  width: 120px;
}
.grid {
  position: relative;
  width: 170%;
  height: 3px;
}
.grid:before {
  content: "";
  position: absolute;
  background-image: linear-gradient(to right, #01feff, transparent);
  background-size: 100% 100%;
  width: 100%;
  height: 3px;
  top: 83px;
  left: -49%;
}
.gridRight {
  position: relative;
}

.gridRight:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  top: 83px;
  left: -49%;
  background-image: linear-gradient(to left, #01feff, transparent);
  background-size: 50% 100%;
  animation: dongRight 2s linear infinite;
}

.gridLeft {
  position: relative;
}

.gridLeft:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  top: 83px;
  left: -49%;
  background-image: linear-gradient(to right, #01feff, transparent);
  background-size: 50% 100%;
  animation: dongLeft 2s linear infinite;
}
@keyframes dongLeft {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes dongRight {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes dongBottom {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes dongTop {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0;
  }
}
</style>

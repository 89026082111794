<template>
  <div class="flex flex-start">
    <div class="flex flex-col flex-center items-middle">
      <div class="line" v-show="isShowLine"></div>
      <div class="byq-box flex-col flex-center items-middle">
        <div class="blank-box"></div>
        <div class="byqBottom">
          <img src="@/assets/imgs/byq.png" style="height: 50px" />
        </div>
        <div class="blank-box"></div>
        <div class="blank-box"></div>
      </div>
      <div class="middle-box flex flex-col flex-center items-middle">
        <div class="line2"></div>
        <div class="middle-content flex">
          <div class="device-content">
            <div class="acu-box">
              <acu :acu-info="acuInfo" :acuLine="acuLine"></acu>
            </div>
            <div class="device" style="margin-top: -40px">
              <device :device-info="deviceInfo"></device>
            </div>
          </div>

          <div class="load-box">
            <load></load>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Acu from "./acu2.vue";
import device from "./device.vue";
import Load from "./load3.vue";
export default {
  components: { device, Load, Acu },
  props: {
    deviceInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    acuLine: {
      type: String,
      default: "",
    },
    acuInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowLine: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    deviceInfo(newValue) {
      this.deviceInfo = newValue;
    },
    acuInfo(newValue) {
      this.acuInfo = newValue;
    },
    acuLine(newValue) {
      this.acuLine = newValue;
    },
    isShowLine(newValue) {
      this.isShowLine = newValue;
    },
  },
};
</script>
<style scoped>
.line {
  margin-left: -50%;
  width: 51%;
  height: 3px;
  background-image: linear-gradient(to left, #01feff, transparent);
  background-size: 100% 100%;
}
.line2 {
  width: 95%;
  height: 1px;
  background: #07d3db;
}
.blank-box {
  width: 80px;
  height: 60px;
}
.middle-box,
.device-content,
.load-box {
  margin-top: -70px;
}

.byq {
  position: relative;
}
.byq:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background: #07d3db;
  background-size: 100% 100%;
  height: 65px;
  top: -115%;
  left: 50%;
}
.byq:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background: #07d3db;
  background-size: 100% 100%;
  height: 55px;
  top: 93%;
  left: 50%;
}

.byqTop {
  position: relative;
}
.byqTop:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100px;
  background-image: linear-gradient(to top, transparent, #01feff);
  background-size: 100% 50%;
  height: 60px;
  top: -130%;
  left: 50%;
  animation: dongTop 2s linear infinite;
}
.byqTop:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100px;
  background-image: linear-gradient(to top, transparent, #01feff);
  background-size: 100% 50%;
  height: 55px;
  top: 93%;
  left: 50%;
  animation: dongTop 2s linear infinite;
}
.byqBottom {
  position: relative;
}
.byqBottom:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  height: 60px;
  top: -115%;
  left: 50%;
  animation: dongBottom 2s linear infinite;
}
.byqBottom:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 200px;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  height: 55px;
  top: 93%;
  left: 50%;
  animation: dongBottom 2s linear infinite;
}
@keyframes dongLeft {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes dongRight {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes dongBottom {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes dongTop {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0;
  }
}
</style>

<template>
  <div class="content-box" style="width: 100%">
    <div class="line-box flex flex-center">
      <div class="black-box">
        <!-- <div class="acu" :class="acuLine"></div> -->
      </div>
    </div>
    <div class="line grid-blank"></div>
    <div class="content flex space-around" style="width: 100%">
      <div class="blank-box1"></div>
      <div class="ark-box flex space-around" style="width: 100%">
        <div v-for="(v, i) in deviceInfo" :key="i">
          <div class="blank-box">
            <div class="blank-info flex flex-col" v-if="deviceInfo.length > 0">
              <div class="black-info3">
                <list-info-3 :value="v.power" unit="kW"></list-info-3>
                <list-info-3 :value="v.voltage" unit="V"></list-info-3>
                <list-info-3 :value="v.soc" unit="%"></list-info-3>
              </div>
            </div>
            <div class="blank-info flex flex-col" v-else>
              <list-info
                :label="$t('info.power')"
                :value="v.power"
                unit="kW"
              ></list-info>
              <list-info
                :label="$t('info.voltage')"
                :value="v.voltage"
                unit="V"
              ></list-info>
              <list-info label="SOC" :value="v.soc" unit="%"></list-info>
            </div>
          </div>
          <div
            class="box ark flex flex-col flex-center items-middle"
            :class="v.deviceClass"
          >
            <div class="icon" @click="toDevice(v, i)" style="cursor: pointer">
              <img src="@/assets/imgs/ark4.png" style="height: 75px" />
            </div>
            <div class="info">
              <div
                class="info-content"
                v-show="v.status === '运行' || v.status == 2"
              >
                <div class="point bg-green"></div>
                <div class="text-green">{{ $t("sysData.operation") }}</div>
              </div>
              <div
                class="info-content"
                v-show="v.status === '故障' || v.status == 3"
              >
                <div class="point bg-red"></div>
                <div class="text-red">{{ $t("sysData.breakDowm") }}</div>
              </div>
              <div
                class="info-content"
                v-show="v.status === '空闲' || v.status == 1"
              >
                <div class="point bg-purple"></div>
                <div class="text-purple">空闲</div>
              </div>
              <div style="text-align: center; font-weight: 600; margin: 8px 0">
                {{ v.arkName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blank-box4"></div>
      <!-- 负载 -->
    </div>
  </div>
</template>

<script>
import ListInfo from "../../../components/list-info.vue";
import listInfo3 from "./list-info3.vue";
export default {
  components: { listInfo3, ListInfo },
  data() {
    return {};
  },
  props: {
    deviceInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    acuLine: {
      type: String,
      default: "",
    },
    siteName: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    deviceInfo(newValue) {
      this.deviceInfo = newValue;
      console.log(this.deviceInfo, "device");
    },
    acuLine(newValue) {
      this.acuLine = newValue;
    },
  },
  methods: {
    toDevice(v, i) {
      this.$router.push({
        name: "device",
        query: {
          siteId: v.siteId,
          slaveId: i + 1,
          siteName: this.siteName,
        },
      });
    },
  },
};
</script>
<style scoped>
.black-box {
  position: relative;
  height: 110px;
  width: 200px;
}
.acu {
  position: relative;
}
.acu:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 110px;
  top: -80%;
  left: 50%;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 100%;
}
.acuBottom {
  position: relative;
}
.acuBottom:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 110px;
  top: -80%;
  left: 50%;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  animation: dongBottom 2s linear infinite;
}
.acuTop {
  position: relative;
}
.acuTop:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 110px;
  top: -80%;
  left: 50%;
  background-image: linear-gradient(to top, transparent, #01feff);
  background-size: 100% 50%;
  animation: dongTop 2s linear infinite;
}

.line {
  height: 1px;
  background: #07d3db;
}
.ark {
  position: relative;
}
.ark:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95px;
  top: -77%;
  left: 50%;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 100%;
}
.arkBottom {
  position: relative;
}
.arkBottom:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95px;
  top: -77%;
  left: 50%;
  background-image: linear-gradient(to top, #01feff, transparent);
  background-size: 100% 50%;
  animation: dongBottom 2s linear infinite;
}
.arkTop {
  position: relative;
}
.arkTop:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95px;
  top: -77%;
  left: 50%;
  background-image: linear-gradient(to top, transparent, #01feff);
  background-size: 100% 50%;
  animation: dongTop 2s linear infinite;
}
.point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.info-content {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
@keyframes dongLeft {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes dongRight {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

@keyframes dongBottom {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes dongTop {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0;
  }
}
</style>
